import axios from "axios";
import { endpoints } from "config/api";
import { getToken } from "./helpers/utilities";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

//get All Diagnostics api
export const getAllMedicineListApi = (params) => {
  let request = axios({
    method: "get",
    url: endpoints.medicine.GET_ALL_MEDICINE_LIST,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params: params,
  }).catch(axiosErrorHandler);
  return request;
};

//upload medicine
export const uploadMedicineOrder = (body) => {
  let request = axios({
    method: "post",
    url: endpoints.medicine.UPLOAD_MEDICINE,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: body,
  }).catch(axiosErrorHandler);
  return request;
};

//add new address
export const AddNewAddressApi = (body) => {
  let request = axios({
    method: "post",
    url: endpoints.medicine.ADD_NEW_ADDRESS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: body,
  }).catch(axiosErrorHandler);
  return request;
};

//get all address api
export const GetAllAddressApi = () => {
  let request = axios({
    method: "get",
    url: endpoints.medicine.GET_ALL_MEDICINE_ADDRESS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//delete medicine address api
export const DeleteAddressMedicineApi = (id) => {
  let request = axios({
    method: "delete",
    url: `${endpoints.medicine.DELETE_ADDRESS_MEDICINE}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//Update medicine address api
export const UpdateAddressApiCall = (id, data) => {
  let request = axios({
    method: "put",
    url: `${endpoints.medicine.UPDATE_ADDRESS_MEDICINE}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//get all cart products
export const GetAllCartProductApi = () => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_ALL_CART}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//Update cart
export const UpdateCart = (data) => {
  let request = axios({
    method: "PATCH",
    url: `${endpoints.medicine.GET_ALL_CART}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//get all cart products
export const GetAllProductSearchApi = (data) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_SEARCH_MEDICINE}?name=${data}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};
export const GetAlternativeProductSearchApi = (data) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_ALTERNATIVE_MEDICINE}/${data}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};
export const GetLabtestBySearch = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.GET_SEARCH_LAB_TEST}?name=${data.name}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data:data.searchData
  }).catch(axiosErrorHandler);
  return request;
};

export const getProductDetailApi = (id) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_SELECTED_PRODUCT_DETAIL}/${id}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  });
  return request;
};

export const AddtoCartAPi = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.ADD_MEDICINE_TO_CART}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};
export const updateCartAddress = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.UPDATE_CART_ADDRESS}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const saveInboundData=(data)=>{
  
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.SAVE_INBOUND_DATA +"/inbound/create" + `?type=${data.type}`}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
}

//coupon list
export const getPharmacyCouponListApi = (vertical) => {
  let request = axios({
    method: "GET",
    url: vertical
      ? `${endpoints.medicine.GET_PHARMACY_COUPON}?verticals=${vertical}`
      : `${endpoints.medicine.GET_PHARMACY_COUPON}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};
export const getNLabs=(payload)=>{
  
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.GET_NLABS}?`+payload.query,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data:payload.data

  }).catch(axiosErrorHandler);
  return request;
}

export const getStores=(payload)=>{
  
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.GET_STORES}?`+payload.query,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
      origintoken:"ea905fcbecccb788fdde2651cf4ff7d1"


    },
    data:payload.data

  }).catch(axiosErrorHandler);
  return request;
}

//create order
export const CreateOrderApi = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.CREATE_OREDR}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//approve order api
export const approveOrderApi = (id) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.APPROVE_OREDR}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//get most order
export const GetMostOrderedApi = (data) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_MOST_ORDERED}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  });
  return request;
};

export const getPrimaryAddressApi = (id) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_CART_ADDRESS}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const GetCartOrderDetailApi = (id) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.CREATE_OREDR}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//cancel order api
export const CancelOrderApi = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.CANCEL_ORDER}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//get uploaded prescription
export const GetUploadedPrescriptionApi = () => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_UPLOADED_PRESCRIPITON}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//delete uploaded medicine prescription api
export const DeleteUploadedPrescriptionApi = (id) => {
  let request = axios({
    method: "DELETE",
    url: `${endpoints.medicine.GET_UPLOADED_PRESCRIPITON}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//additional discount mail trigger
export const AdditionalDiscountMailTriggerApi = () => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.ADDITIONAL_DISCOUNT_MAIL}`,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const GetAllHospitals = (payload) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.GET_HOSPITALS}`,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data:payload.data
  }).catch(axiosErrorHandler);
  return request;
};
export const GetSuggestionHospital = (payload) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.medicine.GET_SUGGESTION}`,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data:payload
  }).catch(axiosErrorHandler);
  return request;
};

export const GetHospitalById = (params) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.medicine.GET_HOSPITAL_BY_ID}`,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    params:params
  }).catch(axiosErrorHandler);
  return request;
};


