import routers from "routes/routes";
import moment from "moment";
import axios from "axios";

export const history = require("history").createBrowserHistory({});

let routerCache = {};

export const pageNavigationByName = (locationDetails) => {
  let { name: locationName, params = "", ...rest } = locationDetails;

  let pathname = "";

  let locationProps = rest || {};

  if (routerCache.hasOwnProperty(locationName)) {
    pathname = routerCache[locationName];
  } else {
    for (let i = 0; i < routers.length; i++) {
      let { name = "", childrens = [], path: parentPath } = routers[i];

      if (name === locationName) {
        pathname = parentPath;
        routerCache[locationName] = parentPath;
        break;
      } else {
        for (let j = 0; j < childrens.length; j++) {
          let { name = "", path = "" } = childrens[j];

          if (name === locationName) {
            let fullPath = `${parentPath}${path}`;
            pathname = fullPath;
            routerCache[locationName] = fullPath;
            break;
          }
        }
      }

      if (pathname) break;
    }
  }

  if (params) {
    pathname = Object.keys(params).reduce((acc, keyName) => {
      return acc.replace(`:${keyName}`, params[keyName]);
    }, pathname);
  }

  history.push({ pathname, ...locationProps });
};

export const getMonth = (month) => {
  switch (month) {
    case "Jan":
      return 0;
    case "Feb":
      return 1;
    case "Mar":
      return 2;
    case "Apr":
      return 3;
    case "May":
      return 4;
    case "Jun":
      return 5;
    case "Jul":
      return 6;
    case "Aug":
      return 7;
    case "Sep":
      return 8;
    case "Oct":
      return 9;
    case "Nov":
      return 10;
    case "Dec":
      return 11;
    default:
      return 1;
  }
};

export const dateDiff = (orderStartDate, orderEndDate = false) => {
  let finalValue;

  let currentDate = moment([
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ]);

  let startDate = moment([
    String(orderStartDate).split(",")[0].split(" ")[2],
    getMonth(String(orderStartDate).split(",")[0].split(" ")[1]),
    String(orderStartDate).split("th")[0],
  ]);

  let endDate = moment([
    String(orderEndDate).split(",")[0].split(" ")[2],
    getMonth(String(orderEndDate).split(",")[0].split(" ")[1]),
    String(orderEndDate).split("th")[0],
  ]);

  if (orderEndDate) {
    finalValue = endDate.diff(startDate, "days");
  } else {
    finalValue = currentDate.diff(startDate, "days");
  }

  return finalValue > 1 ? `${finalValue} days` : `${finalValue} day`;
};

export const isGeneralImage = (data) => {
  let urls = [];
  data.items.map((list) => {
    list.imgUrls.map((url) => {
      urls.push(url);
    });
  });
  let isGeneral = urls.some((list) => {
    return String(list).includes("General+New");
  });
  return isGeneral ? "Yes" : "No";
};

export const getFirstLetter = (value) => {
  if (!value) return;
  if (Array.isArray(value)) {
    let letter = "",
      lastIndex = value?.length - 1;

    if (value?.length <= 0) return;
    if (value[0]) letter += value[0]?.charAt(0)?.toUpperCase();
    if (lastIndex && value[lastIndex])
      letter += value[lastIndex]?.charAt(0)?.toUpperCase();

    return letter;
  }
  return value?.charAt(0).toUpperCase();
};

export function debounce(cb, delay = 1000) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

export const throttle = function (cb, delay=1500) {

  let timeout, lastrun;

  return  (...args) =>{

    const now = Date.now();

    if (now - lastrun >= delay) {
      // If enough time has passed since the last run
      cb(...args);
      lastrun = now; // Update lastrun to current time
    } 
    else {

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args);
        lastrun = Date.now();
      }, delay - (Date.now() - lastrun));
    }

  }
}
export const getGoogleAdress = async ({ lat, lng }) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
        process.env.GOOGLE_API_KEY || "AIzaSyCPQm-Gl4aIgNuj5v8l_J34EEDfGJtZCbU"
      }`
    );
    console.log("response ---- ", response);
    return response;
  } catch (error) {
    console.log("error >> ", error);
    return false;
  }
};
// export const axiosErrorHandler = (error) => {
//   if (error.response) return error.response;
//   else if (error.request) return error.request;
//   else return error.message
// }

export const subscriptionWidget = (value) => {
  if (!value) return;
  if (
    (value && String(value).toUpperCase() == "MD000") ||
    String(value).toUpperCase() == "MD001"
  ) {
    return true;
  } else {
    return false;
  }
};

export const converTimeToTwelveHours = (data) => {
  let time = data?.split(":");
  let seconds = time[1] ?? "00";
  let time1 =
    String(Number(time[0]) > 12 ? Number(time[0]) - 12 : time[0]) +
    ":" +
    seconds +
    (Number(time[0]) > 12 ? " PM" : " AM");
  return time1;
};
export const getAge = (dateString) => {
  var ageInMilliseconds = new Date() - new Date(dateString);
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
};
