import React, { Component } from "react";
// import styles from "./button.module.scss";
import "./normalButton.scss";
import { BsArrowRight } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { IoAdd } from "react-icons/io5";

import { SyncDisabledTwoTone } from "@material-ui/icons";

export class NormalButton extends Component {
  render() {
    const {
      className = "",
      label = "",
      onClick,
      id,
      type = "submit",
      disabled = false,
      authButton = false,
      authButtonConsult = false,
      loginButton = false,
      rightIcon = "",
      searchIcon = "",
      addIcon = "",
      dashBoardTransparent = false,
      dashboardPrimaryButton = false,
      dashBoardTransparentBtn = false,
      bookConsultBtn = false,
      registerLoginBtn = false,
      startButton = false,
      invoicePrimaryBtn = false,
      invoiceTransparentBtn = false,
      searchButton = false,
      modalPrimaryBtn = false,
      modalSecondaryBtn,
      btn_outline,
      smallBtn = false,
      mobileCardButton,
      mobileConsult,
      mobileSpecialist,
      coupoButton,
      viewAndApplyBtn,
      addressNewButton,
      shopNow,
      genderButton,
      scLoginButton,
      scBuyButton,
      authSubscription1,
      authSubscription2,
      genderTransparent,
      simpleCancel,
      simpleDelete,
      linkButton
    } = this.props;
    return (
      <div>
        <button
          id={id}
          type={type}
          className={`cursor-pointer 
                     ${authButton ? "authButton" : ""}
                     ${loginButton ? "loginButton" : ""}
                     ${dashBoardTransparent ? "dashBoardTransparent" : ""}
                     ${dashboardPrimaryButton ? "dashboardPrimaryButton" : ""}
                     ${dashBoardTransparentBtn ? "dashBoardTransparentBtn" : ""}
                     ${startButton ? "startButton" : ""}
                     ${bookConsultBtn ? "bookConsultBtn" : ""}
                     ${registerLoginBtn ? "registerLoginBtn" : ""}
                     ${invoicePrimaryBtn ? "invoicePrimaryBtn" : ""}
                     ${invoiceTransparentBtn ? "invoiceTransparentBtn" : ""}
                     ${searchButton ? "searchButton" : ""}
                     ${modalPrimaryBtn ? "modalPrimaryBtn" : ""}
                     ${modalSecondaryBtn ? "modalSecondaryBtn" : ""}
                     ${btn_outline ? "btn_outline" : ""}
                     ${mobileCardButton ? "mobileCardButton" : ""}
                     ${authButtonConsult ? "authButtonConsult" : ""}
                     ${mobileConsult ? "mobileConsult" : ""}
                     ${mobileSpecialist ? "mobileSpecialist" : ""}
                     ${disabled ? "disabled" : ""}
                     ${smallBtn ? "smallBtn" : ""}
                     ${coupoButton ? "coupoButton" : ""}
                     ${viewAndApplyBtn ? "viewAndApplyBtn" : ""}
                     ${addressNewButton ? "addressNewButton" : ""}
                     ${shopNow ? "shopNow" : ""}
                     ${genderButton ? "genderButton" : ""}
                     ${scLoginButton ? "sc-button" : ""}
                     ${scBuyButton ? "sc-buybutton" : ""}
                     ${authSubscription1 ? "authSubscription1" : ""}
                     ${authSubscription2 ? "authSubscription2" : ""}
                     ${genderTransparent ? "genderTransparent" : ""}
                     ${simpleCancel ? "simpleCancel" : ""}
                     ${simpleDelete ? "simpleDelete" : ""}
                     ${linkButton ? "linkButton" : ""}
                     ${className}`}
          onClick={onClick}
          disabled={disabled}
          onKeyPress={onClick}
        >

          {addIcon !== "" && (
            <IoAdd color="#0a5394" className="fs-20 fw-500 mr-1" />
          )
          }
          {label}

          {rightIcon !== "" ? (
            <BsArrowRight className="fs-20 fw-500 ml-3" />
          ) : // <span className={`btn-right-icon ${rightIcon}`}></span>
            null}
          {searchIcon === "search" ? (
            <FiSearch className="fs-20 fw-500 " />
          ) : // <span className={`btn-right-icon ${rightIcon}`}></span>
            null}
        </button>
      </div>
    );
  }
}

export default NormalButton;
