// import { HomeActionType } from "service/actionType"

const initialState = {
  userDetail: {
    mobileNumber: "",
    password: "",
  },
  abha: {
    abhaTxnId: "",
    patientPhoneNumber: ""
  },
  mStore: {
    storeData: {}
  },
  patientId: "",
  wearableSource: "",
  registerUser: {},
  userData: {},
  mobileNumber: {},
  appointmentCancelId: "",
  multipleFamilyMember: [],
  rescheduleDiagnosisAppt: {},
  diagnosticLocation: {
    id: "",
    testName: "",
  },
  medicine: {
    quantity: 0
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER_DETAIL":
      return {
        ...state,
        userDetail: { ...payload },
      };

    case "PATIENT_ID":
      return {
        ...state,
        patientId: payload,
      };
    case "DIAGNOSTIC_LOCATION":
      return {
        ...state,
        diagnosticLocation: payload,
      };
    case "REGISTER_USER":
      return {
        ...state,
        registerUser: { ...payload },
      };
    case "MOBILE_NUMBER_LOGIN":
      return {
        ...state,
        mobileNumber: { ...payload },
      };
    case "CANCEL_APPOINTMENT_NUMBER":
      return {
        ...state,
        appointmentCancelId: payload,
      };
    case "GET_USER_PROFILE_DATA":
      return {
        ...state,
        userData: payload,
      };
    case "GET_USER_PROFILE_WEARABLE":
      return {
        ...state,
        wearableSource: payload,
      };
    case "MULTI_FAMILY_MEMBER":
      return {
        ...state,
        multipleFamilyMember: payload,
      };

    case "RESCHEDULE_DIAGNOSIS_APPOINTMENT":
      return {
        ...state,
        rescheduleDiagnosisAppt: payload,
      };
    case "ABHA_TXN_ID_OTP": return {
      ...state,
      abha: { ...state.abha, abhaTxnId: payload }
    }
    case "ABHA_PATIENT_NUMBER": return {
      ...state,
      abha: { ...state.abha, patientPhoneNumber: payload }
    }
    case "ADD_STORE_DATA": return {
      ...state,
      mStore: payload
    }
    case "UPDATE_ADDRESS_ID": return {
      ...state,
      userData: {...state.userData, addressId: payload }
    }
    case "UPDATE_MEDICINE_CART": return {
      ...state,
      userData: {...state.userData, medicineCart: payload }
    }
    // case "UPDATE_MED_QTY":return{
    //   ...state,
    //   medicine:  {quantity:payload}
    // }
    default:
      return state;
  }
};
