import FooterComp from "component/common/FooterComp";
import Header from "component/common/Header";
import React, { useEffect } from "react";
import MobileStickyTab from "component/common/MobileStickyTab";
import { getToken } from "service/helpers/utilities";

export function AuthLayout(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scroll({ behavior: "smooth" });
  });
  return (
    <div>
      <Header />
      <div className="login-wrapper pageBackground">{props.children}</div>
      <FooterComp />
      <div className="d-md-none d-block">
        {getToken() != null && <MobileStickyTab />}
      </div>
    </div>
  );
}
