import axios from "axios";
import { endpoints } from "config/api";
import { getToken } from "./helpers/utilities";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

export const saveWebinarData = params => {
    let request = axios({
      method: "post",
      url: endpoints.myProfile.webinar,
      headers: {
        Authorization: "Bearer " + getToken(),
        source: "PATIENT WEB",
      },
      data: params,
    }).catch(axiosErrorHandler);
    return request;
  };
  export const getWebinarDetail = params => {
    let request = axios({
      method: "get",
      url: endpoints.myProfile.webinarDetails,
      headers: {
        Authorization: "Bearer " + getToken(),
        source: "PATIENT WEB",
      },
      
    }).catch(axiosErrorHandler);
    return request;
  };
  
  export const getTenantById = params => {
    let request = axios({
      method: "get",
      url: `${endpoints.auth.GET_TENANT_BY_ID}/${params}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        source: "PATIENT WEB",
      },
    }).catch(axiosErrorHandler);
    return request;
  };
  

  
//get All Diagnostics api