import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";

const MobileRedirect = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    const pathname = window.location.pathname || "";
   
    const AccessUrl = ["/consult/video-consult", "/auth/terms"];

    if (!AccessUrl.includes(pathname) && !pathname.includes("/familyTree")) {
      // if (isAndroid || isIOS) {
      //   const playStoreUrl = "meradoc://app";
      //   window.location.href = playStoreUrl;
      //   const fallbackUrl = isIOS
      //     ? "https://apps.apple.com/app/meradoc/id1609362664"
      //     : "https://play.google.com/store/apps/details?id=com.meradoc";
      //   setTimeout(() => {
      //     window.location.href = fallbackUrl;
      //   }, 3000); // Adjust the timeout as needed
      // }
    }
    // const isMobile =
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   );
    // debugger;
    // if (isMobile) {
    //   const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    //   const isAppInstalled = () => {
    //     if (iOS) {
    //       debugger;
    //       const appInstalled =
    //         window.navigator.standalone ||
    //         window.matchMedia("(display-mode: standalone)").matches;

    //       return appInstalled;
    //     } else {
    //       const scheme = "meradoc://app"; // Replace with your app scheme

    //       const checkAppInstalled = new Promise((resolve) => {
    //         const iframe = document.createElement("iframe");
    //         iframe.style.display = "none";
    //         iframe.onload = () => resolve(true);
    //         iframe.onerror = () => resolve(false);
    //         iframe.src = scheme;
    //         document.body.appendChild(iframe);

    //         setTimeout(() => {
    //           document.body.removeChild(iframe);
    //           resolve(false);
    //         }, 1000); // Adjust the timeout as needed
    //       });

    //       return checkAppInstalled;
    //     }
    //   };
    //   debugger;
    //   if (isAppInstalled && isAppInstalled.length > 0) {
    //     console.log("app installed");
    //     // Redirect to the app
    //     const playStoreUrl = "meradoc://app";
    //     window.location.href = playStoreUrl;
    //   } else {
    //     console.log("app not installed");
    //     // Redirect to the app store
    //     const appStoreUrl = iOS
    //       ? "https://apps.apple.com/app/meradoc/id1609362664"
    //       : "https://play.google.com/store/apps/details?id=com.meradoc";

    //     window.location.href = appStoreUrl;
    //   }
    // }
  }, []);

  return <>{children}</>;
};

export default MobileRedirect;
