import SideBarComp from "component/common/SideBarComp";
import React, { Fragment } from "react";

import "./NavBar.scss";

const NavBar = ({ isOpen, toggle }) => {
  return (
    <Fragment>
      <div className={`nav-bar ${isOpen ? "open" : ""}`}>
        <div>
          <SideBarComp />
        </div>
      </div>
      <div
        className={`nav-overlay ${isOpen ? "open" : ""}`}
        onClick={() => toggle()}
      ></div>
    </Fragment>
  );
};

export default NavBar;
