import axios from "axios";

import { history } from "helpers";
import { logoutapi } from "service/Auth";
import { useDispatch, useSelector } from "react-redux";
import { WearableSource } from "redux/action/home";
import { Toast } from "./toast";

//axios Instance
export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

//token storage
export const saveToken = (token) => {
  return localStorage.setItem("token", token);
};

//get token
export const getToken = () => {
  return localStorage.getItem("token");
};

//logout
export const logout = () => {
  logoutapi();
  axiosInstance.defaults.headers.common["Authorization"] = "";
  localStorage.clear();
  history.push("/");
  window.location.reload(true);
  Toast({ type: "success", message: "Logged Out successfully " });
};
