import axios from "axios";
import { endpoints } from "config/api";
import { getToken } from "./helpers/utilities";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

//get All Diagnostics api
export const getAllDiagnosticsApi = (params) => {
  let request = axios({
    method: "get",
    url: endpoints.diagnostics.GET_ALL_DIAGNOSTICS_LIST,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    params: params,
  }).catch(axiosErrorHandler);
  return request;
};
//getPreferredOptionsApi
export const getProductDetailPreferredOptionsApi = (params) => {
  let request = axios({
    method: "get",
    url: `${endpoints.diagnostics.GET_ALL_PRODUCT_PREFERRED_OPTION}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

//upload Diagnostics api
export const uploadDiagnosticsApi = (body) => {
  let request = axios({
    method: "post",
    url: endpoints.diagnostics.UPLOAD_DIAGNOSTICS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: body,
  }).catch(axiosErrorHandler);
  return request;
};

//Get partner api test
export const GetPartnerTestApi = (params) => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.GET_PARTNER_TEST,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

//Get cities api
export const GetDiagnosisCitiesApi = () => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.GET_ALL_CITIES_LIST,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//Get Test based on city api
export const GetTestBasedOnCityApi = (params) => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.GET_TEST_BASED_CITY,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  });
  return request;
};

//Get Slots for lalpath diagnostic api
export const GetSelectedSlotsDiagnosisApi = (params) => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.GET_DIAGNOSTIC_SLOTS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

//Get Slots for redcliff diagnostic api
export const GetRedcliffSlotsApi = (params) => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.GET_REDCLIFF_DIAGNOSTIC_SLOTS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

//Book Slots for diagnosis api
export const BookSlotsDiagnosisApi = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.BOOK_DIAGNOSTIC_SLOTS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//Book Slots for diagnosis api
export const CreateOrderDiagnosticApi = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.CREATE_DIAGNOSTIC_ORDER,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//add to cart diagnostic
export const addToCartApi = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.DIAGNOSTIC_ADD_CART,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const GetTestDetailonApi = (id, params) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.GET_TEST_BASED_CITY}/${id}`,
    headers: {
      Authorization: getToken() == null ? "" : "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

export const GetDiagnosisCartApi = (param) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.DIAGNOSTIC_ADD_CART}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params: param,
  }).catch(axiosErrorHandler);
  return request;
};

export const UpdateDiagnosisCartApi = (data) => {
  let request = axios({
    method: "patch",
    url: `${endpoints.diagnostics.DIAGNOSTIC_ADD_CART}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//familymemberdiagnostic
export const GetDiagnosticCartFamilyMember = () => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.GET_DIAGNOSTIC_FAMILY_MEMBERS}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const GetDiagnosticOrderApi = (id) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.DIAGNOSTIC_ORDER}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const CancelDiagnosticOrderApi = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.diagnostics.CANCEL_DIAGNOSTIC_ORDER}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const GetDiagnosisLabBranchApi = (params) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.GET_BRANCH_DAIGNOSIS}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

export const GetAllDiagnosticProductSearchApi = (params) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.GET_SEARCH_PARTNER_TEST}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params,
  }).catch(axiosErrorHandler);
  return request;
};

export const labSearch = (data) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.diagnostics.LAB_SEARCH}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};




export const DiagnosisRescheduleSlotApi = (data, id) => {
  let request = axios({
    method: "POST",
    url: `${endpoints.diagnostics.RESCHEDULE_DIAGNOSIS_SLOT}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const GetDiagnosisComparisonCartApi = (city_id) => {
  let request = axios({
    method: "GET",
    url: `${endpoints.diagnostics.DIAGNOSTIC_ADD_CART}?isComparisonNeeded=true&city_id=${city_id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//add to cart for select preferred diagnostic
export const replaceDiagnosticPreferredApi = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.DIAGNOSTIC_ADD_CART_REPLACE,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};


export const orangeHealthFetchServiceability = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.ORANG_HEALTH_FETCH_SERVICEABILITY,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const selectedDateAgilus = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.ORANG_HEALTH_FETCH_SERVICEABILITY,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

export const getSlotAgilus = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.GET_SLOT_AGILUS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};


export const cancelOrderAgilus = (data) => {
  let request = axios({
    method: "GET",
    url: endpoints.diagnostics.CANCEL_ORDER_AGILUS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};


export const checkOrderStatus = (data) => {
  let request = axios({
    method: "POST",
    url: endpoints.diagnostics.CHECK_ORDER_STATUS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};





