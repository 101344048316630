import axios from "axios";
import { endpoints } from "config/api";
import { getToken } from "./helpers/utilities";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

//send otp api
export const sendOtpApi = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.SEND_OTP,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    data: data,
  });
  return request;
};

//register verify otp
export const registerVerifyOtp = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.REGISTER_USER,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: data,
  });
  return request;
};

//create password
export const createPassword = (data, token) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.CREATE_PASSWORD,
    headers: {
      Authorization: "Bearer " + (token || getToken()),
      source: "PATIENT WEB",
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

//multi signin
export const MultiSignInLogin = (data) => {
  let request = axios({
    method: "get",
    url: endpoints.auth.GET_MULTIACCOUNT,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    params: data,
  });
  return request;
};

//login with mobile number
export const loginWithNumberApi = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.SEND_OTP,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    data: data,
  });
  return request;
};
export const loginWithEmailApi = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.SEND_EMAIL_OTP,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    data: data,
  });
  return request;
};

//password signIn
export const passwordSignin = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.PASSWORD_SIGNIN,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    data: data,
  });
  return request;
};

export const forgetPasswordApi = (data) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.FORGET_PASSWORD,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

//corporate register
export const corporateRegisterApi = (data) => {
  let request = axios({
    method: "get",
    url: endpoints.auth.CORPORATE_SLUG,
    headers: {
      Authorization: "",
      source: "PATIENT WEB",
    },
    params: data,
  });
  return request;
};

//update token based on given token
export const tokenWithTokenApi = (token) => {
  let request = axios({
    method: "post",
    url: endpoints.auth.SSO_VALIDATE,
    headers: {
      Authorization: "Bearer " + token,
      source: "PATIENT WEB",
    },
  });
  return request;
};

//update token based on given token
export const logoutapi = () => {
  let request = axios({
    method: "post",
    url: endpoints.auth.LOGOUT,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  });
  return request;
};
