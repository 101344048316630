const routers = [
  //initial route
  {
    component: "VideoLayout",
    path: "/meraclinic",
    auth: false,
    name: "MeraDoc",
    exact: false,
    redirect: "/meraclinic/",
    childrens: [
      {
        component: "Page",
        path: "/*",
        componentPath: "pages/page",
        name: "Page",
        auth: false,
        exact: false,
      },
    ],
  },
  //career route
  // {
  //   component: "VideoLayout",
  //   path: "/careers",
  //   auth: false,
  //   name: "MeraDoc",
  //   exact: false,
  //   redirect: "/careers/",
  //   childrens: [
  //     {
  //       component: "Careers",
  //       path: "/*",
  //       componentPath: "pages/Careers",
  //       name: "Careers",
  //       auth: false,
  //       exact: false,
  //     },
  //   ],
  // },

  {
    component: "Career",
    path: "/career",
    componentPath: "pages/Career/Career",
    name: "Career",
    auth: false,
    exact: false,
  },

  {
    component: "MediaCenter",
    path: "/media-center",
    componentPath: "pages/MediaCenter",
    name: "MediaCenter",
    auth: false,
    exact: true,
  },
  // Web View
  {
    component: "VideoLayout",
    path: "/familyTree/:id/:isDoctor?",
    auth: false,
    name: "MeraDoc",
    exact: true,
    componentPath: "pages/FamilyTree/WebView",
  },

  {
    component: "CancerLandingPage",
    path: "/cancer-screening",
    componentPath: "pages/CancerLanding",
    name: "CancerLandingPage",
    auth: false,
    exact: true,
  },
  {
    component: "CancerLandingPage",
    path: "/cancer-screening/planDetail/:id",
    componentPath: "pages/CancerLanding/PlanDetail",
    name: "CancerPlanDetail",
    auth: false,
    exact: true,
  },
  {
    path: "/",
    redirect: "/",
  },

  {
    path: "/flipkarthealth",
    redirect: "/auth/register/flipkarthealth",
  },
  {
    component: "CommonLayout",
    path: "/",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "LandingPage",
        path: "",
        componentPath: "pages/LandingPage/LandingPage",
        name: "LandingPage",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "CommonLayout",
    path: "/diagnostics",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "DiagnosticsLandingPage",
        path: "",
        componentPath: "pages/Diagnostics/DiagnosticsLandingPage",
        name: "DiagnosticsLandingPage",
        auth: false,
        exact: true,
      },
     

    ],
  },

  {
    component: "Corporate",
    path: "/corporate",
    componentPath: "pages/Corporate",
    name: "Corporate",
    auth: false,
    exact: true,
  },


  // {
  //   component: "CommonLayout",
  //   path: "/diagnostics",
  //   auth: false,
  //   name: "MeraDoc",
  //   exact: true,
  //   redirect: "",
  //   childrens: [
  //     {
  //       component: "Webinars",
  //       path: "",
  //       componentPath: "pages/Webinars",
  //       name: "Webinars",
  //       auth: false,
  //       exact: true,
  //     },
  //   ],
  // },
  {
    component: "CommonLayout",
    path: "/surgery-care",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "SurgeryCare",
        path: "",
        componentPath: "pages/SurgeryCare",
        name: "SurgeryCare",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "CommonLayout",
    path: "/genomic-wellness",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "hayStack",
        path: "",
        componentPath: "pages/HayStack/hStack",
        name: "hay Stack collab",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "CommonLayout",
    path: "/search-medicine",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "MedicineLandingPage",
        path: "",
        componentPath: "pages/MedicinePage/MedicineLandingPage",
        name: "MedicineLandingPage",
        auth: false,
        exact: true,
      },
    ],
  },

  {
    component: "CommonLayout",
    path: "/diagnostic-choice",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "MedicineLandingPage",
        path: "",
        componentPath: "pages/Diagnostics/DiagnosticChoice",
        name: "diagnosticChoise",
        auth: false,
        exact: true,
      },

    ],
  },

  {
    component: "CommonLayout",
    path: "/offers",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "Offer",
        path: "",
        componentPath: "pages/Offer/Offer",
        name: "Offer",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "CommonLayout",
    path: "/about-us",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "AboutUs",
        path: "",
        componentPath: "pages/AboutUs/AboutUs",
        name: "AboutUs",
        auth: false,
        exact: true,
      },
    ],
  },

  {
    component: "CommonLayout",
    path: "/blogs",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "BlogsNew",
        path: "",
        componentPath: "pages/Blogs/blogNew",
        name: "BlogsNew",
        auth: false,
        exact: true,
      },
    ],
  },


  {
    component: "CommonLayout",
    path: "/book-specialist",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "ListAllSpecialist",
        path: "",
        componentPath: "pages/Dashboard/ConsultationBooking/ListAllSpecialist",
        name: "ListAllSpecialist",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "CommonLayout",
    path: "/Webinars",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "Webinars",
        path: "",
        componentPath: "pages/Webinars",
        name: "Webinars",
        auth: false,
        exact: true,
      },
    ],
  },

  {
    component: "Wellness",
    path: "/wellness",
    componentPath: "pages/Wellness",
    name: "Wellness",
    auth: false,
    exact: true,
  },

  {
    component: "CommonLayout",
    path: "/subscription-plans/:id",
    auth: false,
    name: "MeraDoc",
    exact: true,
    redirect: "",
    childrens: [
      {
        component: "Subcription",
        path: "",
        componentPath: "pages/Subcription/Subcription",
        name: "Subcription",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "ContactUs",
    path: "/contact-us",
    componentPath: "pages/ContactUs",
    name: "ContactUs",
    auth: false,
    exact: true,
  },

  {
    component: "CommonLayout",
    path: "/Abha",
    auth: false,
    name: "Abha",
    exact: false,
    redirect: "/Abha/abha",
    childrens: [
      {
        component: "Index",
        path: "/create-aadhar",
        componentPath: "pages/AbhaRegistration/Index.jsx",
        name: "createAbha",
        auth: false,
        exact: true,
      },
      {
        component: "Index",
        path: "/create-driving",
        componentPath: "pages/AbhaRegistration/Index.jsx",
        name: "createAbhaDr",
        auth: false,
        exact: true,
      },
      {
        component: "Index",
        path: "/abha",
        componentPath: "pages/Abha/index.jsx",
        name: "mainPage",
        auth: false,
        exact: true,
      },

      {
        component: "",
        path: "/abhaRegistration",
        componentPath: "pages/Abha/index.jsx",
        name: "Index",
        auth: false,
        exact: true,
      },
      // Abha Retrive
      {
        component: "AbhaRetrival",
        path: "/abharetrive",
        componentPath: "pages/AbhaRetrival/main",
        name: "AbhaRetrival",
        auth: false,
        exact: true,
      },]
  },

  {
    component: "CommonLayout",
    path: "/store",
    auth: false,
    name: "store",
    exact: false,
    redirect: "/store",
    childrens: [
      {
        component: "Index",
        path: "/store",
        componentPath: "pages/medpayClone/index.js",
        name: "store",
        auth: false,
        exact: true,
      },
      {
        component: "Index",
        path: "/StoreIndiVidual",
        componentPath: "pages/medpayClone/StoreIndiVidual.js",
        name: "StoreIndiVidual",
        auth: false,
        exact: true,
      },
      {
        component: "medpayClone",
        path: "/stores",
        componentPath: "pages/MedicinePage/ViewStoresPage",
        name: "ViewStoresPage",
        auth: false,
        exact: true,
      },


    ]

  },

  {
    component: "CommonLayout",
    path: "/mdiagnostic",
    auth: false,
    name: "mdiagnostic",
    exact: false,
    redirect: "/mdiagnostic",
    childrens: [
      {
        component: "medpayClone",
        path: "/diagnostic",
        componentPath: "pages/medpayClone/Diagnostics",
        name: "ViewlabPage",
        auth: false,
        exact: true,
      },
      {
        component: "medpayClone",
        path: "/all",
        componentPath: "pages/medpayClone/DiagnosticAll",
        name: "ViewlabAll",
        auth: false,
        exact: true,
      },
      {
        component: "medpayClone",
        path: "/diagnosticIndivdual",
        componentPath: "pages/medpayClone/DiagnosticIndivdual",
        name: "DiagnosticIndivdual",
        auth: false,
        exact: true,
      },

    ]
  },
  {
    component: "CommonLayout",
    path: "/webinar",
    auth: false,
    name: "MeraDoc",
    exact: false,
    redirect: "",
    childrens: [
      {
        component: "TempPages",
        path: "/",
        componentPath: "pages/TempPages/WebinarPage",
        name: "WebinarPage",
        auth: false,
        exact: true,
      },
    ]
  },
  {
    component: "CommonLayout",
    path: "/hospitals",
    auth: false,
    name: "MeraDoc",
    exact: false,
    redirect: "",
    childrens: [
      {
        component: "TempPages",
        path: "/",
        componentPath: "pages/medpayClone/Hospital",
        name: "hospitals",
        auth: false,
        exact: true,
      },
      {
        component: "TempPages",
        path: "/IndiHospital",
        componentPath: "pages/medpayClone/IndiHopital",
        name: "IndiHospital",
        auth: false,
        exact: true,
      },
      {
        component: "TempPages",
        path: "/AllHospitals",
        componentPath: "pages/medpayClone/AllHospitals",
        name: "allHospitals",
        auth: false,
        exact: true,
      },
    ]


  },




  {
    component: "CommonLayout",
    path: "/MeraDoc",
    auth: false,
    name: "MeraDoc",
    exact: false,
    redirect: "/MeraDoc/landing-page",
    childrens: [
      {
        component: "LandingPage",
        path: "/landing-page",
        componentPath: "pages/LandingPage/LandingPage",
        name: "LandingPage",
        auth: false,
        exact: true,
      },
      // senior Service
      {
        component: "LandingPage",
        path: "/senior-care",
        componentPath: "pages/SeniorCare/SeniorCare",
        name: "Serniorcare page",
        auth: false,
        exact: true,
      },
      // {
      //   component: "hayStack",
      //   path: "/genomic-wellness",
      //   componentPath: "pages/HayStack/hStack",
      //   name: "hay Stack collab",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "authenticate",
        path: "/sso/authenticate/:id",
        componentPath: "pages/SSO/authenticate",
        name: "authenticate",
        auth: false,
        exact: true,
      },
      {
        component: "authenticate",
        path: "/sso/authenticate",
        componentPath: "pages/SSO/authenticate",
        name: "authenticate",
        auth: false,
        exact: true,
      },
      // {
      //   component: "AboutUs",
      //   path: "/about-us-page",
      //   componentPath: "pages/AboutUs/AboutUs",
      //   name: "AboutUs",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "SmartHealth",
        path: "/Smart-Clinic",
        componentPath: "pages/SmartHealth/Index",
        name: "SmartHealth",
        auth: false,
        exact: true,
      },
      // {
      //   component: "SurgeryCare",
      //   path: "/surgery-care",
      //   componentPath: "pages/SurgeryCare",
      //   name: "SurgeryCare",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "DiagnosticsTestList",
        path: "/diagnosticsList",
        componentPath: "pages/Diagnostics/DiagnosticsTestList",
        name: "DiagnosticsTestList",
        auth: false,
        exact: true,
      },
      {
        component: "Medicine",
        path: "/medicine",
        componentPath: "pages/MedicinePage",
        name: "Medicine",
        auth: false,
        exact: true,
      },
      // {
      //   component: "MedicineDetailComponent",
      //   path: "/medicine-details",
      //   componentPath: "pages/MedicinePage/MedicineDetailComponent",
      //   name: "MedicineDetailComponent",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "MedicineDetailComponent",
        path: "/medicine-details",
        componentPath: "pages/MedicinePage/MedicineDetailComponent",
        name: "MedicineDetailComponent",
        auth: false,
        exact: true,
      },
      {
        component: "Cart",
        path: "/Cart",
        componentPath: "pages/MedicinePage/Cart.js",
        name: "Cart",
        auth: false,
        exact: true,
      },
      {
        component: "OrderPage",
        path: "/order/:id",
        componentPath: "pages/MedicinePage/OrderPage",
        name: "OrderPage",
        auth: false,
        exact: true,
      },

      {
        component: "Diagnostics",
        path: "/diagnostics",
        componentPath: "pages/Diagnostics",
        name: "Diagnostics",
        auth: false,
        exact: true,
      },
      // Diagnostics pages
      {
        component: "DiagnosticsOrders",
        path: "/diagnostics-orders",
        componentPath: "pages/Diagnostics/DiagnosticsOrders",
        name: "DiagnosticsOrders",
        auth: false,
        exact: true,
      },
      {
        component: "TestDetails",
        path: "/testDetails/:id",
        componentPath: "pages/Diagnostics/TestDetails",
        name: "TestDetails",
        auth: false,
        exact: true,
      },
      {
        component: "FamilyDetails",
        path: "/FamilyDetails",
        componentPath: "pages/Diagnostics/FamilyDetails",
        name: "FamilyDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddFamilyDetails",
        path: "/add-familydetails",
        componentPath: "pages/Diagnostics/AddFamilyDetails",
        name: "AddFamilyDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewAddress",
        path: "/add-newaddress",
        componentPath: "pages/Diagnostics/AddNewAddress",
        name: "AddNewAddress",
        auth: false,
        exact: true,
      },
      {
        component: "DiagnosticsCart",
        path: "/diagnostics-cart",
        componentPath: "pages/Diagnostics/DiagnosticsCart",
        name: "DiagnosticsCart",
        auth: false,
        exact: true,
      },
      {
        component: "HomeCollection",
        path: "/home-collection",
        componentPath: "pages/Diagnostics/HomeCollection",
        name: "HomeCollection",
        auth: false,
        exact: true,
      },

      {
        component: "JoinUs",
        path: "/join-us",
        componentPath: "pages/Dashboard/JoinUs",
        name: "JoinUs",
        auth: false,
        exact: true,
      },
      {
        component: "SpeakersProfile",
        path: "/speakers-profile",
        componentPath: "pages/SpeakersProfile",
        name: "SpeakersProfile",
        auth: false,
        exact: true,
      },
      {
        component: "UploadPrescription",
        path: "/upload-prescription",
        componentPath: "pages/UploadPrescription",
        name: "UploadPrescription",
        auth: true,
        exact: true,
      },
      // {
      //   component: "MedicineLandingPage",
      //   path: "/medicine-landingpage",
      //   componentPath: "pages/MedicinePage/MedicineLandingPage",
      //   name: "MedicineLandingPage",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "OrderReview",
        path: "/diagnostics/order-review/:id",
        componentPath: "pages/Diagnostic/DiagnosticOrderReview",
        name: "OrderReview",
        auth: false,
        exact: true,
      },
      {
        component: "OrderReview",
        path: "/order-review/:id",
        componentPath: "pages/MedicinePage/OrderReview",
        name: "OrderReview",
        auth: false,
        exact: true,
      },
      {
        component: "UploadMedicinePrescription",
        path: "/prescription-upload",
        componentPath: "pages/MedicinePage/Prescription",
        name: "UploadMedicinePrescription",
        auth: false,
        exact: true,
      },


      // {
      //   component: "BlogsNew",
      //   path: "/blogs-page",
      //   componentPath: "pages/Blogs/blogNew",
      //   name: "BlogsNew",
      //   auth: false,
      //   exact: true,
      // },
      // {
      //   component: "Webinars",
      //   path: "/webinars",
      //   componentPath: "pages/Webinars",
      //   name: "Webinars",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "BookConsultation",
        path: "/book-consultation",
        componentPath: "pages/BookConsultation",
        name: "BookConsultation",
        auth: true,
        exact: true,
      },
      // {
      //   // component: "SearchMedicine",
      //   // path: "/search-medicine",
      //   // componentPath: "pages/FAQ/index",
      //   // name: "SearchMedicine",
      //   // auth: false,
      //   // exact: true,

      //   component: "MedicineLandingPage",
      //   path: "/search-medicine",
      //   componentPath: "pages/MedicinePage/MedicineLandingPage",
      //   name: "MedicineLandingPage",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "SearchDiagnosis",
        path: "/search-diagnosis",
        componentPath: "pages/FAQ/FAQNew",
        name: "SearchDiagnosis",
        auth: false,
        exact: true,
      },
      //diagnosis new
      // {
      //   component: "DiagnosticsLandingPage",
      //   path: "/diagnostic",
      //   componentPath: "pages/Diagnostics/DiagnosticsLandingPage",
      //   name: "DiagnosticsLandingPage",
      //   auth: false,
      //   exact: true,
      // },
      // {
      //   component: "Subcription",
      //   path: "/subcription/:id",
      //   componentPath: "pages/Subcription/Subcription",
      //   name: "Subcription",
      //   auth: false,
      //   exact: true,
      // },

      {
        component: "JobDetails",
        path: "/job-detail",
        componentPath: "pages/Career/JobDetails",
        name: "JobDetails",
        auth: false,
        exact: false,
      },
      {
        component: "JobApplication",
        path: "/job-application",
        componentPath: "pages/Career/JobApplication",
        name: "JobApplication",
        auth: false,
        exact: false,
      },

      {
        component: "PrescriptionChanged",
        path: "/medicine-update",
        componentPath: "pages/MedicinePage/PrescriptionChanged",
        name: "PrescriptionChanged",
        auth: false,
        exact: true,
      },
      {
        component: "AddTravelMembers",
        path: "/add-travel-members/:userId",
        componentPath: "pages/AddMembers/AddTravelMembers",
        name: "AddMembers",
        auth: false,
        exact: true,
      },
      {
        component: "AddFamilyMembers",
        path: "/add-family-members",
        componentPath: "pages/AddMembers/AddFamilyMembers",
        name: "AddFamilyMembers",
        auth: false,
        exact: false,
      },

    ],
  },
  {
    component: "TestLayout",
    path: "/verify",
    auth: false,
    name: "MeraDoc",
    exact: false,
    redirect: "/verify",
    childrens: [
      {
        component: "LandingPage",
        path: "/crashtest/:id",
        componentPath: "pages/LandingPage/CrashTest",
        name: "LandingPage",
        auth: false,
        exact: true,
      },
      {
        component: "MobileViewWearable",
        path: "/wearable",
        componentPath: "pages/Dashboard/MobileViewWearable",
        name: "MobileViewWearable",
        auth: false,
        exact: true,
      },
    ],
  },

  {
    component: "AuthLayout",
    path: "/blog",
    auth: false,
    name: "blog",
    exact: false,
    redirect: "/blog",
    childrens: [
      {
        component: "Blogs",
        path: "/",
        componentPath: "pages/Blogs",
        name: "Blogs",
        auth: false,
        exact: true,
      },

    ]
  },

  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "Terms",
        path: "/terms",
        componentPath: "pages/Terms",
        name: "Terms",
        auth: false,
        exact: true,
      },
      {
        component: "Privacypolicy",
        path: "/privacy-policy",
        componentPath: "pages/Privacypolicy",
        name: "Privaypolicy",
        auth: false,
        exact: true,
      },
      {
        component: "RefundPolicy",
        path: "/refund-policy",
        componentPath: "pages/RefundPolicy/RefundPolicy",
        name: "RefundPolicy",
        auth: false,
        exact: true,
      },
      {
        component: "Register",
        path: "/register",
        componentPath: "pages/Auth/Register",
        name: "Register",
        auth: false,
        exact: true,
      },
      {
        component: "Fkh",
        path: "/register/fkh",
        componentPath: "pages/Auth/Fkh",
        name: "Fkh",
        auth: false,
        exact: true,
      },
      {
        component: "FlipkartHealth",
        path: "/register/flipkarthealth",
        componentPath: "pages/Auth/FlipkartHealth",
        name: "FlipkartHealth",
        auth: false,
        exact: true,
      },
      {
        component: "VerifyOTP",
        path: "/verify-otp",
        componentPath: "pages/Auth/VerifyOTP",
        name: "VerifyOTP",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        path: "/forgot-password",
        componentPath: "pages/Auth/ForgotPassword",
        name: "ForgotPassword",
        auth: false,
        exact: true,
      },
      {
        component: "VerifyOTPMobile",
        path: "/verify-otp-mobile",
        componentPath: "pages/Auth/VerifyOTPMobile",
        name: "VerifyOTPMobile",
        auth: false,
        exact: true,
      },
      {
        component: "VerifyOTPPassReset",
        path: "/verify-otp-password-reset",
        componentPath: "pages/Auth/VerifyOTPPassReset",
        name: "VerifyOTPPassReset",
        auth: false,
        exact: true,
      },

      {
        component: "CreatePassword",
        path: "/create-password",
        componentPath: "pages/Auth/CreatePassword",
        name: "CreatePassword",
        auth: false,
        exact: true,
      },
      {
        component: "MedicalHistory",
        path: "/medical-history/:form",
        componentPath: "pages/MedicalHistory",
        name: "MedicalHistory",
        auth: true,
        exact: true,
      },
      {
        component: "CreateProfile",
        path: "/create-profile",
        componentPath: "pages/Dashboard/MyProfile/CreateProfile",
        name: "CreateProfile",
        auth: true,
        exact: true,
      },
      {
        component: "BuySubscription",
        path: "/subscription-plans",
        componentPath: "pages/Dashboard/subscription/SubscriptionPlans",
        name: "SubscriptionPlans",
        auth: false,
        exact: true,
      },
      {
        component: "CorporateRegister",
        path: "/register/corporate/:corporate",
        componentPath: "pages/Auth/CorporateRegister",
        name: "CorporateRegister",
        auth: false,
        exact: true,
      },
      {
        component: "ExpireVideoCall",
        path: "/call-info",
        componentPath: "pages/Dashboard/ExpireVideoCall",
        name: "ExpireVideoCall",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/patient",
    auth: true,
    name: "Dashboard",
    exact: false,
    redirect: "/patient",
    childrens: [
      // DASHBOARD
      {
        component: "DeleteAccount",
        path: "/delete-account",
        componentPath: "pages/Dashboard/MyProfile/DeleteAccount",
        name: "DeleteAccount",
        auth: false,
        exact: true,
      },
      {
        component: "MyProfile",
        path: "/my-profile",
        componentPath: "pages/Dashboard/MyProfile/MyProfile",
        name: "MyProfile",
        auth: true,
        exact: true,
      },

      {
        component: "EditProfile",
        path: "/edit-profile",
        componentPath: "pages/Dashboard/MyProfile/EditProfile",
        name: "EditProfile",
        auth: true,
        exact: true,
      },
      {
        component: "Consultations",
        path: "/consultations",
        componentPath: "pages/Dashboard/Consultations/Consultations",
        name: "Consultations",
        auth: true,
        exact: true,
      },
      {
        component: "Consultations",
        path: "/update-consultation/:id",
        componentPath: "pages/Dashboard/Consultations/UpdateConsultation",
        name: "UpdateConsultation",
        auth: true,
        exact: true,
      },
      {
        component: "ConsultationDetails",
        path: "/consultation-detail",
        componentPath: "pages/Dashboard/Consultations/ConsultationDetails",
        name: "ConsultationDetails",
        auth: true,
        exact: true,
      },
      {
        component: "HealthRecord",
        path: "/health-record",
        componentPath: "pages/Dashboard/HealthRecords/HealthRecord",
        name: "HealthRecord",
        auth: true,
        exact: true,
      },
      {
        component: "UploadReports",
        path: "/upload-reports",
        componentPath: "pages/Dashboard/HealthRecords/UploadReports",
        name: "UploadReports",
        auth: true,
        exact: true,
      },
      {
        component: "MyOrders",
        path: "/my-orders",
        componentPath: "pages/Dashboard/MyOrders",
        name: "MyOrders",
        auth: true,
        exact: true,
      },
      {
        component: "Settings",
        path: "/settings",
        componentPath: "pages/Dashboard/Settings",
        name: "Settings",
        auth: true,
        exact: true,
      },
      {
        component: "Faq",
        path: "/faq",
        componentPath: "pages/Dashboard/faq",
        name: "Faq",
        auth: true,
        exact: true,
      },
      {
        component: "MeraDocPrescription",
        path: "/view-prescription",
        componentPath: "pages/Dashboard/MeraDocPrescription",
        name: "MeraDocPrescription",
        auth: true,
        exact: true,
      },
      {
        component: "Notification",
        path: "/notifications",
        componentPath: "pages/Dashboard/Notification",
        name: "Notification",
        auth: true,
        exact: true,
      },

      // FamilyTree
      {
        component: "FamilyTree",
        path: "/family-tree",
        componentPath: "pages/FamilyTree/FamilyTree",
        name: "FamilyTree",
        auth: true,
        exact: true,
      },
      {
        component: "FamilyMember",
        path: "/family-member/:id?",
        componentPath: "pages/FamilyTree/FamilyMember/index.js",
        name: "FamilyMember",
        auth: true,
        exact: true,
      },
      {
        component: "MemberTable",
        path: "/members",
        componentPath: "pages/FamilyTree/MemberTable",
        name: "MemberTable",
        auth: true,
        exact: true,
      },
      {
        component: "MedicalHistory",
        path: "/medical-history/:form/:id",
        componentPath: "pages/FamilyTree/MedicalHistory/index.js",
        name: "MedicalHistory",
        auth: true,
        exact: true,
      },
      {
        component: "FamilyMemberDetails",
        path: "/member-details/:id",
        componentPath:
          "pages/FamilyTree/FamilyMemberDetails/FamilyMemberDetails.js",
        name: "FamilyMemberDetails",
        auth: true,
        exact: true,
      },
      // {
      //   component: "ListSubcription",
      //   path: "/subcription-list",
      //   componentPath: "pages/Subcription/ListSubcription",
      //   name: "ListSubcription",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "SubcriptionSummary",
        path: "/subcription-summary",
        componentPath: "pages/Subcription/SubcriptionSummary",
        name: "SubcriptionSummary",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AuthLayout",
    path: "/book",
    auth: false,
    name: "Dashboard",
    exact: false,
    redirect: "/book",
    childrens: [
      {
        component: "BookConsultation",
        path: "/book-consultationGP",
        componentPath: "pages/Dashboard/ConsultationBooking/BookConsultation",
        name: "GPBookConsultation",
        auth: true,
        exact: true,
      },
      // {
      //   component: "ListAllSpecialist",
      //   path: "/list-specialist",
      //   componentPath: "pages/Dashboard/ConsultationBooking/ListAllSpecialist",
      //   name: "ListAllSpecialist",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "BookSpecialist",
        path: "/book-specialist",
        componentPath: "pages/Dashboard/ConsultationBooking/BookSpecialist",
        name: "BookSpecialist",
        auth: false,
        exact: true,
      },
      {
        component: "SummaryPage",
        path: "/summary-page",
        componentPath: "pages/Dashboard/ConsultationBooking/SummaryPage",
        name: "SummaryPage",
        auth: true,
        exact: true,
      },
      {
        component: "SpecialistSummary",
        path: "/specialist-summary-page",
        componentPath: "pages/Dashboard/ConsultationBooking/SpecialistSummary",
        name: "SpecialistSummary",
        auth: false,
        exact: true,
      },
      {
        component: "InvoiceGP",
        path: "/invoice",
        componentPath: "pages/Dashboard/ConsultationBooking/InvoiceGP",
        name: "InvoiceGP",
        auth: true,
        exact: true,
      },
      {
        component: "ConsultationInformation",
        path: "/consultation-information",
        componentPath: "pages/Dashboard/Consultations/ConsultationInformation",
        name: "ConsultationInformation",
        auth: true,
        exact: true,
      },
      {
        component: "DoctorBio",
        path: "/doctor-bio",
        componentPath: "pages/Dashboard/ConsultationBooking/DoctorBio",
        name: "DoctorBio",
        auth: true,
        exact: true,
      },
      {
        component: "AddFamilyMember",
        path: "/add-family",
        componentPath: "pages/Dashboard/AddFamilyMember",
        name: "AddFamilyMember",
        auth: true,
        exact: true,
      },
      {
        component: "SubcriptionSummary",
        path: "/subcription-summary",
        componentPath: "pages/Subcription/SubcriptionSummary",
        name: "SubcriptionSummary",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "VideoLayout",
    path: "/consult",
    auth: false,
    name: "Dashboard",
    exact: false,
    redirect: "/consult",
    childrens: [
      {
        component: "VideoCall",
        path: "/video-consult",
        componentPath: "pages/Dashboard/VideoCall",
        name: "VideoCall",
        auth: false,
        exact: true,
      },
      {
        component: "EndVideoCall",
        path: "/end-call",
        componentPath: "pages/Dashboard/EndVideoCall",
        name: "EndVideoCall",
        auth: false,
        exact: true,
      },
    ],
  },
];
export default routers;
