import axios from "axios";
import { endpoints } from "config/api";
import { getToken } from "./helpers/utilities";
import { history } from "helpers";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

//get patient details api
export const getPatientBasicDetailApi = (id = '') => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.GET_MY_PROFILE + `${id ? '/' + id : ''}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};
export const getPatientBasicDetailApiForLanding = () => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.GET_MY_PROFILE,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  });
  // .catch(axiosErrorHandler);
  return request;
};

//get state and district by pincode
export const GetStateAndPincodeByApi = (pin) => {

  let request = axios({
    method: "get",
    url: `${endpoints.myProfile.GET_BY_PINCODE}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params: pin,
  }).catch(axiosErrorHandler);
  return request;
};
//editProfileDetails
export const editPatientBasicDetailApi = (data) => {
  let request = axios({
    method: "put",
    url: endpoints.myProfile.EDIT_MY_PROFILE,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
  return request;
};

//get lap reports
export const getLabReportsApi = (params) => {
  let request = axios({
    method: "post",
    url: endpoints.myProfile.GET_LAB_REPORTS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: params,
  }).catch(axiosErrorHandler);
  return request;
};

//change password api
export const updatePasswordApi = (body) => {
  let request = axios({
    method: "put",
    url: endpoints.auth.CHANGE_PASSWORD,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: body,
  }).catch(axiosErrorHandler);
  return request;
};

//get FAQ

export const getFaqApi = (params) => {
  let request = axios({
    method: "get",
    url: endpoints.faq.GET_FAQ,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params: params,
  }).catch(axiosErrorHandler);
  return request;
};

//upload reports api
export const uploadDocumentsApi = async (formData) => {
  let request = await axios({
    method: "post",
    url: endpoints.myProfile.UPLOAD_IMAGES,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: formData,
  }).catch((e) => {
    return axiosErrorHandler(e, "uploadImage");
  });
  return request;
};

//upload bulk image
export const uploadBulkImage = (formData) => {
  let request = axios({
    method: "post",
    url: endpoints.myProfile.REPORT_UPLOAD,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: formData,
  }).catch(axiosErrorHandler);
  return request;
};

//create and update history
export const updateMedicalHistoryApi = (formData) => {
  let request = axios({
    method: "post",
    url: endpoints.myProfile.UPDATE_MEDICAL_HISTORY,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: formData,
  }).catch(axiosErrorHandler);
  return request;
};
//get family member
export const getFamilyMembersApi = (aptId) => {
  let request = axios({
    method: "get",
    url:
      endpoints.myProfile.GET_FAMILY_MEMBERS +
      // "https://apidev.meradoc.com/user/api/v1/user/diagnostics/getFamilyMember" +
      `${aptId ? `?aptId=${aptId}` : ""}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//get By mobile number
export const GetMemberMyNumber = (mobileNumber) => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.GET_MOBILENUMBER_MEMBER,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    params: mobileNumber,
  }).catch(axiosErrorHandler);
  return request;
};

//user header detail
export const getHeaderUserDetail = () => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.HEADER_USER_DETAIL,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//profile completion %
export const profileCompletionApi = () => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.PROFILE_COMPLETION_PERCENT,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const addFamilyMemberApi = (data) => {
  return axios({
    method: "post",
    url: endpoints.myProfile.ADD_FAMILY_MEMBER,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data,
  }).catch(axiosErrorHandler);
};

export const getFamilyTree = (userId) => {
  let url = endpoints.myProfile.GET_FAMILY_TREE;
  if (userId)
    url = `${endpoints.myProfile.GET_FAMILY_TREE_WEB_VIEW}?userId=${userId}`;
  let request = axios({
    method: "get",
    url,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getFamilyMember = ({ famId, id }) => {
  let url = endpoints.myProfile.GET_FAMILY_MEMBER_BY_COND + "?";
  if (famId) url += `famId=${famId}`;
  if (id) url += `id=${id}`;
  let request = axios({
    method: "get",
    url,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const updateFamilyMember = (data) => {
  let request = axios({
    method: "put",
    url: endpoints.myProfile.UPDATE_FAMILY_MEMBER,
    data,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const deleteFamilyMember = (famId) => {
  let request = axios({
    method: "delete",
    url: endpoints.myProfile.DELETE_FAMILY_MEMBER + `/${famId}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getMedicalHistory = (userId) => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.GET_MEDICAL_HISTORY + `?userId=${userId}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getFamilyRelations = () => {
  let request = axios({
    method: "get",
    url: endpoints.myProfile.GET_FAMILY_RELATIONS,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const DeletePrescriptionImage = async (id) => {
  const request = await axios({
    method: "delete",
    url: `${endpoints.myProfile.DELETE_PRESCRIPTION_IMAGE}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

//speak to doctor api
export const SpeakToDoctorApi = async () => {
  const request = await axios({
    method: "get",
    url: `${endpoints.myProfile.GET_NEXT_DOCTOR_SLOT}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const registerPhoneVerifyOtp = (data) => {
  let request = axios({
    method: "post",
    url: `${endpoints.myProfile.REGISTER_USER_PHONE}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const sendPhoneOtpApi = (data) => {
  let request = axios({
    method: "post",
    url: `${endpoints.myProfile.SEND_OTP_PHONE}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};
export const deactivateProfile = (id) => {
  let request = axios({
    method: "put",
    url: `${endpoints.myProfile.DEACTIVETE_PROFILE}/${id}`,
    headers: {
      Authorization: "Bearer " + getToken(),
      source: "PATIENT WEB",
    },

  }).catch(axiosErrorHandler);
  return request;
};
