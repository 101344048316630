import { history } from "helpers";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  addToCartApi,
  replaceDiagnosticPreferredApi,
  GetDiagnosisCartApi,
  UpdateDiagnosisCartApi,
} from "service/Diagnostics";
import { Toast } from "service/helpers/toast";
import { getToken } from "service/helpers/utilities";

const diagnosisCartContext = createContext();

export function DiagnosisCart({ children }) {
  const cart = useDiagnosisCart();
  return (
    <diagnosisCartContext.Provider value={cart}>
      {children}
    </diagnosisCartContext.Provider>
  );
}

export const useDiagnosticCart = () => {
  return useContext(diagnosisCartContext);
};

function useDiagnosisCart() {
  const [diagnosisCartList, setDiagnosisCartList] = useState({});
  const [appliedDiagnosisCoupon, setDiagnosisAppliedCoupon] = useState("");

  useEffect(() => {
    if (getToken() !== null) {
      GetAllCartForDiagnosis();
    }
  }, [getToken()]);

  const GetAllCartForDiagnosis = async (params) => {
    try {
      let { data = {}, status } = await GetDiagnosisCartApi(params);
      if (status == 200) {
        setDiagnosisAppliedCoupon(data?.data?.couponCode);
        setDiagnosisCartList(data?.data);
      }
    } catch (err) {}
  };

  const handleAddToCart = async (item, selectedCity) => {

    if (getToken() !== null) {
      console.log(item,"handleAddToCart",selectedCity)
      if (item) {
        try {
          let body = {
            itemId: item?.id_MD,
            itemName: item?.name_MD,
            city_id: selectedCity?.id ?? "",
            vendor: item?.partner_MD,
          };
          if(item.from){
            body.from =item.from
            body.labData=item.storeDataLocal
          }

          let { data = {}, status, message } = await addToCartApi(body);
          console.log(data,"add to cartData")
          if (status == 200) {
            // setDiagnosisCartList(data?.data);
            Toast({
              type: "success",
              message: data?.message,
            });
            await GetAllCartForDiagnosis();
          }
        } catch (err) {}
      }
    } else {
      let url = "/auth/login";
      if (window?.location?.pathname)
        url += `?refer=${window.location.pathname
          ?.replace?.(/&/g, "---")
          ?.replace?.(/=/g, "--")
          ?.replace?.("?", "-")
          ?.replace?.(/-/g, "_")}`;
      history.push(url);
    }
  };

  const updateCartProduct = async (val, type) => {
    console.log(val, type,"updateCartProduct ")
    let body = {};
    if (type == "delete") {
      body.itemId = val?.itemId.toString();
      body.quantity = 0;
    }
    if (type == "diagnosisAddress") {
      body.addressId = val?.addressId;
      body.pincode=val?.pincode
    }
    if (type == "familyMember") {
      body.famId = val;
    }
    if (type == "coupon") {
      body.couponCode = val;
    }
    if (type == "collection") {
      body.isHomeCollection = val;
    }
    if (type == "cityId") {
      body.cityId = val;
    }
    if (type == "cityName") {
      body.cityName = val;
    }
    if (type == "selectedPartner") {
      body.selectedPartner = val;
    }
    let { data = {}, status } = await UpdateDiagnosisCartApi(body);
    console.log(data,"data UpdateDiagnosisCartApi")
    if (status == 200) {
      await GetAllCartForDiagnosis();
      if (type == "delete") {
        Toast({ type: "success", message: "Product Removed Successfully" });
      }
      return true;
      // Toast({ type: "success", message: data?.message });
    } else {
      return false;
    }
  };

  //add to cart for select preferred diagnostic
  const replaceDiagnosticSelection = async (payload) => {
    try {
      let {
        data = {},
        status,
        message,
      } = await replaceDiagnosticPreferredApi(payload);
      if (status == 200) {
        // Toast({
        //   type: "success",
        //   message: data?.message,
        // });
        //await GetAllCartForDiagnosis();
        return true;
      } else {
        return false;
      }
    } catch (err) {}
  };

  return {
    handleAddToCart,
    diagnosisCartList,
    setDiagnosisAppliedCoupon,
    updateCartProduct,
    appliedDiagnosisCoupon,
    replaceDiagnosticSelection,
    GetAllCartForDiagnosis,
  };
}
