import React, { useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { mainLayoutStyle } from "service/helpers/Constants";
import Header from "../component/common/Header";
import FooterComp from "component/common/FooterComp";
import SideBarComp from "component/common/SideBarComp";
import MobileStickyTab from "component/common/MobileStickyTab";
import { getToken } from "service/helpers/utilities";

export function MainLayout(props) {
  const outerTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#1A4BA1",
      },
      secondary: {
        main: "#FBFBFB",
      },
    },
  });
  const classes = mainLayoutStyle();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scroll({ behavior: "smooth" });
  });


  
  

  return (
    <>
      <div className="mainLayout">
        <div>
          <Header />
        </div>
        

        <div className="mt-md-5 mt-0">
          <div className="d-flex w-100">
            <div className="d-lg-block d-md-none d-none sidebar" id="sidebar">
              <SideBarComp />
            </div>
            <div className="p-md-4 p-3 w-100 mt-md-4 mt-5 " >
              {props.children}
            </div>
          </div>
        </div>

        <div>
          <FooterComp />
          <div className="d-md-none d-block">
            {getToken() != null && <MobileStickyTab />}
          </div>
        </div>
      </div>
    </>
  );
}
