import React, { useState } from "react";
import "./style.scss";
import { history } from "helpers";
import { FiShoppingCart, FiPhoneCall } from "react-icons/fi";
import { BsPersonFill, BsNewspaper, BsPersonPlus } from "react-icons/bs";
import { GiMedicinePills } from "react-icons/gi";
import NormalButton from "component/common/NormalButton/NormalButton";
import { Modal } from "antd";
import medicine from "assets/icons/specialist.svg";
import mobSpecialist from "assets/icons/mobSpecialist.svg";

const MobileStickyTab = () => {
  const [contactAssist, setcontactAssist] = useState(false);

  return (
    <>
      <Modal open={contactAssist} footer={null} closable={false}>
        <div className="my-5">
          <h3 className="text-center poppins">
            Please contact our customercare{" "}
          </h3>
          <p className="fs-20 fw-600 poppins text-center primary-blue text-plain">
            <a
              href="tel:1800 3090 101"
              // target="_blank"
              className="fs-20 fw-600 poppins text-center primary-blue text-plain"
            >
              1800 3090 101
            </a>
          </p>
          <div className="m-auto col-md-6 col-12 pt-5">
            <NormalButton
              label={"OK"}
              authButton
              onClick={() => setcontactAssist(false)}
            />
          </div>
        </div>
      </Modal>
      <div className="stickeyMobileTab">
        <ul>
          <li onClick={() => history.push("/book-specialist")}>
            <div className="d-flex justify-content-center">
              <BsPersonPlus size={20} color="#0e69c2" />
            </div>
            <p>Book Specialist</p>
          </li>
          <li onClick={() => history.push("/search-medicine")}>
            <div className="d-flex justify-content-center">
              {/* <GiMedicinePills size={20} color="#0e69c2" /> */}
              <img src={medicine} width="40%" />
            </div>
            <p>Medicines</p>
          </li>

          <li
            className="consultDivBox"
            onClick={() => history.push("/book/book-consultationGP")}
          >
            <div className="d-flex justify-content-center">
              <div>
                <div className="consultBox">
                  {/* <BsPersonFill size={30} color="white" /> */}
                  <img src={mobSpecialist}/>
                </div>
              </div>
            </div>
            <p>Consult Now</p>
          </li>
          <li
            className="pl-3"
            onClick={() => history.push("/diagnostics")}
          >
            <div className="d-flex justify-content-center">
              <BsNewspaper size={20} color="#0e69c2" />
            </div>
            <p>Diagnostics</p>
          </li>
          <li onClick={() => setcontactAssist(true)}>
            <div className="d-flex justify-content-center">
              <FiPhoneCall size={20} color="#0e69c2" />
            </div>
            <p>Need Assistance</p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileStickyTab;
